export enum ChannelType {
    LIVECHAT = "2e3b69b2999c4c38b45c5076f98e6946",
    TWITTER = "200cfded4cfd4bde977f174bc16bc634",
    FACEBOOK = "eebf03447dbc46068dd217fcd62e586d",
    LINKEDIN = "d7d61128f27a4af7b4bbd78394d65582",
    INSTAGRAM = "8d4446321fa7481cb71ddac1d78cd0ct",
    MAIL = "25132600ff214f18b623479258eecf38",
    SHOPIFY = "t7d61128f27a4bf7b4bbd78394d65572",
    WIX = "w7x61128f27a4b4rd4bbd78394d65512",
    WORDPRESS = "wwx61128f27a4b45d4bbd78394d65516",
    WEBFLOW = "wfx61128f27a4b45d4bbd78394d65519",
    PRESTASHOP = "ppx61128f27a4b4rd4bbd78394d65512",
    GOOGLE = "1",
    UNKNOWN = "0",

}

export function channelTypeName(type: ChannelType): string {
    return Object.keys(ChannelType).find(value => ChannelType[value] == type);
}