import {observable} from "@nx-js/observer-util";
import {Services} from "../../Services";
import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {Network} from "../../../network/Network";
import {ProductType} from "../../../models/product/ProductType";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {ICommunityFileModel} from "../../../models/community/files/ICommunityFileModel";
import {CommunityFileType} from "../../../models/community/files/CommunityFileType";
import {config} from "../../../config";
import {ICommunityFileSearchImagesModel} from "../../../models/community/files/ICommunityFileSearchImagesModel";

export class CommunityFilesService {
    public static files: ICommunityFileModel[] = observable([]);

    public static dispose(): void {

    }

    public static init(): void {
    }


    public static async create(communityId: string, fileType: CommunityFileType, files: File[], progress?: (progress: number) => void, component?: INetworkComponent): Promise<ICommunityFileModel[]> {
        let request = await Network.postFormData(ProductType.COMMUNITY, `/community/files`, {
            communityId: communityId,
            files: files,
            fileType: fileType
        }, component, {
            onUploadProgress: (progressEvent) => {
                if (progress) {
                    progress(progressEvent.loaded * 100 / progressEvent.total);
                }
            }
        });

        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return undefined;
    }

    public static async searchImages(communityId: string, query: string, page: number, component?: INetworkComponent): Promise<ICommunityFileSearchImagesModel> {
        let request = await Network.post(ProductType.COMMUNITY, "/community/files/search/images",
            {
                communityId: communityId,
                query: query,
                page: page
            }, component);

        if (request.status == HttpStatus.OK) {
            return request.data;
        }
    }

    public static async downloadImage(communityId: string, imageId: string, component?: INetworkComponent): Promise<ICommunityFileModel> {
        let request = await Network.post(ProductType.COMMUNITY, "/community/files/download/image",
            {
                communityId: communityId,
                imageId: imageId,
            }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }
    }

    /**
     * link
     */

    public static link(file: ICommunityFileModel) {
        return (config.httpCommunityUrl ?? config.domain) + `/community/files/${file.id}.${file.extension}`;
    }

    /**
     * store
     */

    public static storeAll(files: ICommunityFileModel[]): ICommunityFileModel[] {
        for (let key in files) {
            files[key] = this.store(files[key]);
        }
        return Services.storeAll(files);
    }

    public static store(file: ICommunityFileModel): ICommunityFileModel {
        file.link = this.link(file);
        return Services.store("id", this.files, file);
    }
}