import {Network} from "../../../network/Network";
import {ProductType} from "../../../models/product/ProductType";
import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {ICommunityWritingStyleOutgoingModel} from "../../../models/community/writingStyle/ICommunityWritingStyleOutgoingModel";
import {ICommunityModel} from "../../../models/community/ICommunityModel";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {CommunityService} from "../CommunityService";
import {WebSocketCommunityEventName} from "../../../network/socket/names/WebSocketCommunityEventName";
import {ICommunityWritingStyleIncomingModel} from "../../../models/community/writingStyle/ICommunityWritingStyleIncomingModel";
import {arrayRemove} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/ArrayRemove";

export class CommunityWritingStyleService {

    public static styleEvents: ((order: ICommunityWritingStyleIncomingModel) => void)[] = [];

    public static dispose(): void {
        this.styleEvents = [];
    }

    public static init(): void {
        Network.on(ProductType.COMMUNITY, WebSocketCommunityEventName.COMMUNITY_WRITING_STYLE_UPDATE, (data: ICommunityWritingStyleIncomingModel) => {
            CommunityService.store(data.community);
            this.styleEvents.forEach(value => value(data));
        });
    }

    /**
     * websocket
     */

    static onWritingStyle(component: INetworkComponent, func: (style: ICommunityWritingStyleIncomingModel) => void) {
        this.styleEvents.push(func);
        component.onRemove(() => arrayRemove(this.styleEvents, func));
    }

    public static async create(style: ICommunityWritingStyleOutgoingModel, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.postJson(ProductType.COMMUNITY, `/community/writingStyle`, style, component);
        return request.status == HttpStatus.OK;
    }

    public static async delete(communityId: string, writingStyleId: string, component?: INetworkComponent): Promise<ICommunityModel> {
        let request = await Network.delete(ProductType.COMMUNITY, `/community/writingStyle/${communityId}/${writingStyleId}`, component);

        if (request && request.status == HttpStatus.OK) {
            return CommunityService.store(request.data);
        }

        return undefined;
    }
}