import axios, {AxiosInstance, CancelToken} from "axios";
import {INetworkComponent} from "./types/INetworkComponent";
import {config} from "../config";
import {EntityService} from "../services/entity/EntityService";
import {SedestralMachine} from "../sedestral-interface-modules/sedestral-interface-component/machine/SedestralMachine";
import {SedestralRouter} from "../sedestral-interface-modules/sedestral-interface-router/SedestralRouter";
import {NetworkHeaders} from "./types/NetworkHeaders";
import {ErrorCode} from "./status/error/ErrorCode";
import {INetworkRequestConfig} from "./types/INetworkRequestConfig";
import {HttpStatus} from "./status/HttpStatus";
import {INetworkComponentError} from "./types/INetworkComponentError";
import {ProductType} from "../models/product/ProductType";
import {Resources} from "../resources/Resources";
import {ProductName} from "../models/product/ProductName";
import {SedestralStorage} from "../sedestral-interface-modules/sedestral-interface-component/memory/SedestralStorage";
import {IAccountTwoFactorMethod} from "../models/account/twofactor/IAccountTwoFactorMethod";
import {NetworkSocket} from "./socket/NetworkSocket";
import {
    SedestralDebugger
} from "../sedestral-interface-modules/sedestral-interface-component/debugger/SedestralDebugger";
import {jsonConcat} from "../sedestral-interface-modules/sedestral-interface-component/utilities/JsonConcat";
import {randomInteger} from "../sedestral-interface-modules/sedestral-interface-component/utilities/RandomInteger";
import {queryString} from "../sedestral-interface-modules/sedestral-interface-component/utilities/QueryString";
import {generateVendor} from "../sedestral-interface-modules/sedestral-interface-component/utilities/GenerateVendor";
import {OfferProductSolutionType} from "../models/offer/product/solution/OfferProductSolutionType";

export class Network {
    public static vendor: string;
    public static tenor: AxiosInstance;

    public static sockets: { type: ProductType, socket: NetworkSocket }[] = [];
    public static axios: { type: ProductType, http: AxiosInstance }[] = [];

    public static httpResources: AxiosInstance;
    public static productName: string;

    public static timeoutApi: number = 60000;
    public static timeoutTenor: number = 10000;

    public static router: SedestralRouter;
    public static commonErrors: INetworkComponentError[];

    public static lostConnectionInterval: any = 0;
    public static lostConnection = false;
    public static lostFunctions: (() => void)[] = [];
    public static lostSolvedFunctions: (() => void)[] = [];
    public static startedFunctions: (() => void)[] = [];

    public static _logged: boolean;

    /**
     * logged
     */

    static get logged(): boolean {
        return this._logged;
    }

    static set logged(value: boolean) {
        this._logged = value;
        SedestralStorage.setItem(`${this.productName}-online`, this._logged + "");
    }

    /**
     * init
     */

    public static async init(productName: string): Promise<void> {
        this.productName = productName;
        this._logged = SedestralStorage.getItem(`${this.productName}-online`) == undefined ? false : SedestralStorage.getItem(`${this.productName}-online`) == "true";
        this.vendor = generateVendor(config.domain);

        this.tenor = axios.create({baseURL: config.tenorHost, timeout: this.timeoutTenor});
        this.tenor.defaults.params = {key: config.tenorKey};

        this.httpResources = axios.create({baseURL: config.httpPanelUrl, withCredentials: false, timeout: this.timeoutApi});

        this.registerAxios(ProductType.PANEL, config.httpPanelUrl);
        this.registerAxios(ProductType.COMMUNITY, config.httpCommunityUrl ?? config.domain);

        this.registerSocket(ProductType.PANEL, config.websocketPanelUrl);
        this.registerSocket(ProductType.COMMUNITY, config.websocketCommunityUrl);

        if (config.product != ProductName.toString(ProductType.LIVECHAT)) {
            window["NetworkService"] = this;
        }
    }

    public static initErrors() {
        this.commonErrors = [
            {errorCode: ErrorCode.SITE_CHANNEL_NO_FOUND, message: Resources.t("words.errorSITE_CHANNEL_NO_FOUND")},
            {errorCode: ErrorCode.NO_RECIPIENT, message: Resources.t("words.errorNO_RECIPIENT")},
            {errorCode: ErrorCode.NO_SITE_CHANEl, message: Resources.t("words.errorNO_SITE_CHANNEL")},
            {errorCode: ErrorCode.TWO_FACTOR_TECHNICAL, message: Resources.t("words.errorTWO_FACTOR_TECHNICAL")},
            {errorCode: ErrorCode.CAPTCHA_INCORRECT, message: Resources.t("words.errorCAPTCHA_INCORRECT")},
            {errorCode: ErrorCode.TWO_FACTOR_CODE_INCORRECT, message: Resources.t("words.errorTWO_FACTOR_CODE_INCORRECT")},
            {
                errorCode: ErrorCode.TWO_FACTOR_TRANSACTION_NO_FOUND,
                message: Resources.t("words.errorTWO_FACTOR_TRANSACTION_NO_FOUND")
            },
            {errorCode: ErrorCode.TWO_FACTOR_EXPIRE_CODE, message: Resources.t("words.errorTWO_FACTOR_EXPIRE_CODE")},
            {errorCode: ErrorCode.TWO_FACTOR_ATTEMPT, message: Resources.t("words.errorTWO_FACTOR_ATTEMPT")},
            {
                errorCode: ErrorCode.TWO_FACTOR_BAND_WIDTH_LIMIT,
                message: Resources.t("words.errorTWO_FACTOR_BAND_WIDTH_LIMIT")
            },
            {
                errorCode: ErrorCode.TWO_FACTOR_METHOD_UNAUTHORIZED,
                message: Resources.t("words.errorTWO_FACTOR_METHOD_UNAUTHORIZED")
            },
            {
                errorCode: ErrorCode.TWO_FACTOR_CREATE_INVALID_CODE,
                message: Resources.t("words.errorTWO_FACTOR_CREATE_INVALID_CODE")
            },
            {errorCode: ErrorCode.SITE_CHANNEL_PERMISSION, message: Resources.t("words.errorSITE_CHANNEL_PERMISSION")},
            {errorCode: ErrorCode.PERMISSION, message: Resources.t("words.errorPERMISSION")},
            {errorCode: ErrorCode.VERIFICATION, message: Resources.t("words.errorVERIFICATION")},
            {errorCode: ErrorCode.SIZE_MAX, message: Resources.t("words.errorSIZE_MAX")},
            {errorCode: ErrorCode.NULLITY, message: Resources.t("words.errorNULLITY")},
            {errorCode: ErrorCode.EXCEPTION, message: Resources.t("words.errorEXCEPTION")},
            {errorCode: ErrorCode.CACHE, message: Resources.t("words.errorCACHE")},
            {errorCode: ErrorCode.K8S_DOMAIN_ERROR, message: Resources.t("words.errorK8S_DOMAIN_ERROR")},
            {errorCode: ErrorCode.DOMAIN_FORMAT, message: Resources.t("words.errorDOMAIN_FORMAT"), displayValue: true},
            {errorCode: ErrorCode.DOMAIN_FULL, message: Resources.t("words.errorDOMAIN_FULL")},
            {errorCode: ErrorCode.HOST_INCORRECT, message: Resources.t("words.errorHOST_INCORRECT")},
            {errorCode: ErrorCode.HOST_EXIST, message: Resources.t("words.errorHOST_EXIST")},
            {errorCode: ErrorCode.NAME_EXIST, message: Resources.t("words.errorNAME_EXIST")},
            {errorCode: ErrorCode.WINDOW_EXPIRED, message: Resources.t("words.errorWINDOW_EXPIRED")},
            {errorCode: ErrorCode.EMAIL_ALIAS_EXIST, message: Resources.t("words.errorEMAIL_ALIAS_EXIST")},
            {errorCode: ErrorCode.EMAIL_ALIAS, message: Resources.t("words.errorEMAIL_ALIAS")},
            {errorCode: ErrorCode.EMAIL_EXIST, message: Resources.t("words.errorEMAIL_EXIST")},
            {errorCode: ErrorCode.EMAIL_BLACKLIST, message: Resources.t("words.errorEMAIL_BLACKLIST")},
            {errorCode: ErrorCode.MAIL_FORMAT, message: Resources.t("words.errorMAIL_FORMAT"), displayValue: true},
            {errorCode: ErrorCode.PHONE_FORMAT, message: Resources.t("words.errorPHONE_FORMAT")},
            {errorCode: ErrorCode.MAIL_DOMAIN, message: Resources.t("words.errorMAIL_DOMAIN")},
            {errorCode: ErrorCode.RECIPIENT_FULL, message: Resources.t("words.errorRECIPIENT_FULL")},
            {errorCode: ErrorCode.IMAGE_FORMAT, message: Resources.t("words.errorIMAGE_FORMAT")},
            {errorCode: ErrorCode.NO_CONTACT, message: Resources.t("words.errorNO_CONTACT")},
            {errorCode: ErrorCode.TAX_COUNTRY, message: Resources.t("words.postalCountryTaxCountry")},
            {errorCode: ErrorCode.TAX_TYPE, message: Resources.t("words.taxTypeNotAvailable")},
            {errorCode: ErrorCode.TAX_VALUE_INVALID, message: Resources.t("words.taxValueInvalid")},
            {errorCode: ErrorCode.LOCATION_INVALID, message: Resources.t("words.locationInvalid")},
            {errorCode: ErrorCode.TOKEN_INVALID, message: Resources.t("words.tokenInvalidMessage")},
            {errorCode: ErrorCode.PAYMENT_PROVIDER_CREATE_SUBSCRIPTION, message: Resources.t("words.paymentProviderCreateSubscriptionMessage")},
            {errorCode: ErrorCode.OFFER_QUOTE_EXISTS, message: Resources.t("words.quoteExistsErrorMessage")},
            {errorCode: ErrorCode.CREATE_ARTICLE_IA_FORM_EMPTY, message: Resources.t("words.errorcreateArticleFormEmpty")},
            {errorCode: ErrorCode.TASK_IN_PROGRESS, message: Resources.t("words.taskInProgress")},
            {status: HttpStatus.UNAUTHORIZED, message: Resources.t("words.errorUNAUTHORIZED")},
            {status: HttpStatus.NOT_FOUND, message: Resources.t("words.errorNOT_FOUND")},
            {status: HttpStatus.PRECONDITION_FAILED, message: Resources.t("words.errorPRECONDITION_FAILED")},
            {status: HttpStatus.BAD_GATEWAY, message: Resources.t("words.errorSERVICE_UNAVAILABLE")},
            {status: HttpStatus.SERVICE_UNAVAILABLE, message: Resources.t("words.errorSERVICE_UNAVAILABLE")},
            {status: HttpStatus.INTERNAL_SERVER_ERROR, message: Resources.t("words.errorINTERNAL_SERVER_ERROR")},
            {status: HttpStatus.TOO_MANY_REQUESTS, message: Resources.t("words.errorTOO_MANY_REQUESTS")},
        ];
    }

    /**
     * starting
     */

    public static async start(router?: SedestralRouter): Promise<boolean> {
        if (router != undefined) {
            this.router = router;
            this.router.onStarted = () => this.startedFunctions.forEach(value => value());
        }

        if (await this.onToken()) {
            if (Network.logged) {
                await this.startLogin();
            }
        } else {
            this.executeNetworkLost();
            return false;
        }

        return true;
    }

    public static async startLogin(): Promise<boolean> {
        if (!await this.onLogin()) {
            this.executeNetworkLost();
            return false;
        }

        return true;
    }

    public static async startWebSocket(type: ProductType, onDisconnect?: () => void): Promise<NetworkSocket> {
        let socket = this.getSocket(type);
        socket.onDisconnect = () => {
            if (type == ProductType.PANEL) {
                this.executeNetworkLost();
            }
            if (onDisconnect) {
                onDisconnect();
            }
        };

        if (await socket.start({
            siteId: EntityService.activeSite?.id,
            entityType: EntityService.activeEntity.type,
            activeToken: EntityService.activeToken
        })) {
            return socket;
        }
    }

    /**
     * websocket
     */

    public static async disconnect(type: ProductType, logout?: boolean): Promise<void> {
        this.getSocket(type).disconnect(logout);
    }

    public static emit(type: ProductType, event: string, value: any) {
        Network.getSocket(type).emit(event, value);
    }

    public static async emitSync(type: ProductType, event: string, value: any) {
        return await Network.getSocket(type).emitSync(event, value);
    }

    public static on(type: ProductType, event: string, func: (data: any) => void) {
        Network.getSocket(type)?.on(event, func);
    }

    public static async join(type: ProductType, data: any, route: string): Promise<any> {
        if (data != null) {
            return await Network.emitSync(type, route, data);
        }
    }

    public static getSocket(type?: ProductType): NetworkSocket {
        if (type) {
            return this.sockets.find(value => value.type == type)?.socket;
        }

        return this.sockets.find(value => value.type == ProductType.PANEL)?.socket;
    }

    public static removeSocket(type: ProductType): void {
        let socket = this.sockets.find(value => value.type == type);
        if (socket) {
            this.sockets.splice(this.sockets.indexOf(socket), 1);
        }
    }

    public static registerSocket(type: ProductType, host: string) {
        this.removeSocket(type);
        this.sockets.push({
            type: type, socket: new NetworkSocket(host)
        });
    }

    /**
     * axios
     */

    public static registerAxios(type: ProductType, host: string) {
        let instance = this.axios.find(value => value.type == type);
        if (instance) {
            this.axios.splice(this.axios.indexOf(instance), 1);
        }

        let http = axios.create({baseURL: host, withCredentials: false, timeout: this.timeoutApi});
        http.interceptors.request.use(function (config) {
            if (EntityService.activeToken) {
                config.headers[NetworkHeaders.SESSION_HEADER] = EntityService.activeToken
            }
            if (EntityService.activeSite) {
                config.headers[NetworkHeaders.SITE_HEADER] = EntityService.activeSite.id
            }

            return config;
        });

        this.axios.push({type: type, http: http});
    }

    public static getAxios(type?: ProductType): AxiosInstance {
        if (type) {
            return this.axios.find(value => value.type == type)?.http;
        }

        return this.axios.find(value => value.type == ProductType.PANEL)?.http;
    }

    /**
     * http
     */

    public static async request(type: ProductType, request: (token: CancelToken) => Promise<any>, component?: INetworkComponent, config?: INetworkRequestConfig): Promise<any> {
        let tokenSource = axios.CancelToken.source();
        if (config?.cancelableCallback) {
            config.cancelableCallback(tokenSource);
        }

        if (component && component.onRemove) {
            component.onRemove(() => {
                tokenSource.cancel();
            });
        }

        return SedestralMachine.promise((resolve) => {
            let axiosRequest = request(tokenSource.token);
            axiosRequest.then((response) => {

                resolve(response);

                if (Network.router?.static?.components?.twoFactor?.onSuccess) {
                    Network.router?.static?.components?.twoFactor?.onSuccess(response);
                }

                if (response && response.headers) {
                    this.requestAlert(response.headers);
                }

            }).catch((response) => {

                //Si pas de réponse, on en simule une
                if (!response.response && response?.code !== "ERR_CANCELED") {
                    response = {
                        response: {
                            status: HttpStatus.SERVICE_UNAVAILABLE,
                            data: ''
                        }
                    };
                }

                if (response.response
                    && response.response.data
                    && (response.response.data.status || response.response.data.errorCode)
                    && response.response.status) {

                    this.requestTwoFactor(component, {...response, productType: type});
                    this.requestError(component, response.response.data);

                    if (response.response.status !== HttpStatus.LOCKED) {
                        Network.router?.static?.components?.twoFactor?.clear();
                    }

                } else {

                    if (response.response && response.response.status != HttpStatus.OK) {
                        this.requestError(component, {status: response.response.status});
                    }
                    Network.router?.static?.components?.twoFactor?.clear();
                }

                resolve(response.response);
            });
        });
    }

    public static async requestAlert(headers: any): Promise<void> {
        let alertCode = headers[NetworkHeaders.ALERT_HEADER];
        if (alertCode) {
            Network.router.static.components.notifications.notify(Resources.t("words.alert." + alertCode), undefined, 8000, "success");
        }
    }

    public static async requestError(component: INetworkComponent, data: any): Promise<void> {
        if (data) {
            if (data.status == HttpStatus.PAYMENT_REQUIRED) {
                this.onPaymentRequired(data.currentQuantity, data.limitQuantity, data.solutionType, data.productType);
            } else {
                if (data.errorCode != ErrorCode[ErrorCode.TWO_FACTOR_REQUIRED]) {
                    if (data.errorCode == ErrorCode[ErrorCode.SURFACE_CONTROL]) {

                        if (data.subErrors) {
                            data.subErrors.forEach((subError) => {
                                this.onError(Resources.r("words.error.fieldErrorMessage", {
                                    field: subError.field,
                                    message: subError.message
                                }));
                            });
                        } else if (data.message && data.message.includes("parameter is missing")) {
                            this.onError(Resources.t("words.errorfillEmptyFieldsError"));
                        } else {
                            this.onError(Resources.t("words.errorgenericErrorMessage"));
                            SedestralDebugger.rum?.unHandledError(data.errorCode);
                        }

                    } else if (data.status === HttpStatus.NETWORK_AUTHENTICATION_REQUIRED) {
                        await this.createLogout();
                    } else {
                        let resultCheckError = Network.checkError(component?.networkErrorsHandler, data)
                            || Network.checkError(this.commonErrors, data);

                        if (!resultCheckError) {
                            let message = data.error + ": " + data.message + " | code: " + data.errorCode + " | type: " + data.errorType + " | status: " + data.status;
                            SedestralDebugger.rum?.unHandledError(data.errorCode);
                            this.onError(message);
                        }
                    }
                }
            }
        }
    }

    public static requestTwoFactor(component: INetworkComponent, response) {
        if (response.response.data.errorCode == ErrorCode[ErrorCode.TWO_FACTOR_REQUIRED]) {

            if (Network.router.static.components.twoFactor.onSuccess) {
                try {

                    let data = response.response.headers[NetworkHeaders.TF_TRANSACTION_DATA_HEADER];
                    let method = +parseInt(response.response.headers[NetworkHeaders.TF_TRANSACTION_METHOD_HEADER]) as IAccountTwoFactorMethod;

                    if (method !== undefined && (method == IAccountTwoFactorMethod.TOTP || (method == IAccountTwoFactorMethod.EMAIL && data != undefined))) {
                        Network.router.static.components.twoFactor.create(data, response);
                    } else {
                        this.onError("TWO_FACTOR_REQUIRED not data.");
                    }

                } catch (e) {
                    this.onError("TWO_FACTOR_REQUIRED not data.");
                }
            } else {
                this.onError("TWO_FACTOR_REQUIRED not active.");
            }
        }
    }

    public static async postJson(type: ProductType, url: string, data: any, component?: INetworkComponent, config?: INetworkRequestConfig): Promise<any> {
        let jsonHeaders = {'Content-Type': 'application/json'};
        if (!config) {
            config = {};
        }

        config.headers = jsonConcat(jsonHeaders, config.headers)
        return await Network.request(type, (token) => Network.getAxios(type).post(url, JSON.stringify(data), jsonConcat({cancelToken: token}, config)), component, config);
    }

    public static async postFormData(type: ProductType, url: string, data?: any, component?: INetworkComponent, config?: INetworkRequestConfig): Promise<any> {
        let jsonHeaders = {'Content-Type': 'multipart/form-data'};
        if (!config) {
            config = {};
        }

        config.headers = jsonConcat(jsonHeaders, config.headers)

        let formData = new FormData();
        Object.keys(data).forEach(function (key) {
            if (key != "files") {
                formData.append(key, data[key]);
            }
        });

        if (data.files) {
            data.files.forEach((file: string | Blob) => formData.append('files', file));
        }

        return await Network.request(type, (token) => Network.getAxios(type).post(url, formData, jsonConcat({cancelToken: token}, config)), component);
    }

    public static async post(type: ProductType, url: string, data?: any, component?: INetworkComponent, config?: INetworkRequestConfig, parseQuery: boolean = true): Promise<any> {
        return await Network.request(type, (token) => Network.getAxios(type).post(url, data ? parseQuery ? queryString(data) : data : undefined, jsonConcat({cancelToken: token}, config)), component, config);
    }

    public static async put(type: ProductType, url: string, data?: any, component?: INetworkComponent, config?: INetworkRequestConfig): Promise<any> {
        return await Network.request(type, (token) => Network.getAxios(type).put(url, data ? queryString(data) : undefined, jsonConcat({cancelToken: token}, config)), component, config);
    }

    public static async putJson(type: ProductType, url: string, data: any, component?: INetworkComponent, config?: INetworkRequestConfig): Promise<any> {
        let jsonHeaders = {'Content-Type': 'application/json'};
        if (!config) {
            config = {};
        }

        config.headers = jsonConcat(jsonHeaders, config.headers)
        return await Network.request(type, (token) => Network.getAxios(type).put(url, JSON.stringify(data), jsonConcat({cancelToken: token}, config)), component, config);
    }

    public static async get(type: ProductType, url: string, component?: INetworkComponent, config?: INetworkRequestConfig): Promise<any> {
        return await Network.request(type, (token) => Network.getAxios(type).get(url, jsonConcat({cancelToken: token}, config)), component, config);
    }

    public static async delete(type: ProductType, url: string, component?: INetworkComponent, config?: INetworkRequestConfig): Promise<any> {
        return await Network.request(type, (token) => Network.getAxios(type).delete(url, jsonConcat({cancelToken: token}, config)), component, config);
    }

    public static async retry(response: any, component?: INetworkComponent, config?: INetworkRequestConfig): Promise<any> {
        const type = response.productType ?? ProductType.PANEL;
        switch (response.config.method) {
            case "post":
                try {
                    let jsonData = JSON.parse(response.config.data);
                    return await Network.postJson(type, response.config.url, jsonData, component, config);
                } catch (e) {
                    return await Network.post(type, response.config.url, response.config.data, component, config, false);
                }
            case "put":
                try {
                    let jsonData = JSON.parse(response.config.data);
                    return await Network.putJson(type, response.config.url, jsonData, component, config);
                } catch (e) {
                    return await Network.put(type, response.config.url, response.config.data, component, config);
                }
            case "get":
                return await Network.get(type, response.config.url, component, config);
            case "delete":
                return await Network.delete(type, response.config.url, component, config);
        }
    }

    public static async createLogout() {
        Network.router.onLogout();
        await Network.onLogout();
    }

    /**
     * to override
     */
    public static async onToken(): Promise<boolean> {
        return false;
    }

    public static async onLogin(): Promise<boolean> {
        return false;
    }

    public static async onLogout(): Promise<boolean> {
        return false;
    }

    public static onError(message: string) {

    }

    public static onPaymentRequired(currentQuantity: number, limitQuantity: number, solutionType: OfferProductSolutionType, productType: ProductType) {

    }

    /**
     * network error
     */

    public static onNetworkLost(func: () => void) {
        this.lostFunctions.push(func);
    }

    public static onNetworkLostSolved(func: () => void) {
        this.lostSolvedFunctions.push(func);
    }

    public static executeNetworkLost() {
        if (!this.lostConnection) {
            this.lostConnection = true;
            this.lostFunctions.forEach(value => value());

            this.lostConnectionInterval = setInterval(async () => {
                if (await this.start()) {
                    this.lostConnection = false;
                    clearInterval(this.lostConnectionInterval);
                    this.lostSolvedFunctions.forEach(value => value());
                }
            }, this.getSecondsRetry());
        }
    }

    static getSecondsRetry(): number {
        if (process.env.PRODUCT === "panel") {
            return randomInteger(500, 2000);
        }
        return randomInteger(3000, 6000);
    }

    /**
     * initialized
     */

    public static onStarted(func: () => void) {
        this.startedFunctions.push(func);
    }

    /**
     * SSR
     */

    public static ssrRegisterDomain(context: string) {
        config.domain = context;
        this.vendor = generateVendor(config.domain);
        return context;
    }

    private static checkError(errors: INetworkComponentError[], data: any): boolean {
        let error = undefined;
        if (errors !== undefined) {

            if (data.errorCode != undefined) {
                error = errors.find(value => ErrorCode[value.errorCode] === data.errorCode);
            }

            if (data.status != undefined && error === undefined) {
                error = errors.find(value => value.status === data.status);
            }

            if (error === undefined) {
                error = errors.find(value => value?.defaultError === true);
            }

            if (error !== undefined) {

                if (error.message && error.message !== "none") {
                    this.onError(error.message + (error.displayValue ? " (" + data.message + ")" : ""));
                } else if (error.handle) {
                    error.handle();
                }
            }
        }

        return error !== undefined;
    }
}