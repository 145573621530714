import {observable} from "@nx-js/observer-util";
import {ISiteChannelModel} from "../../../models/site/channel/ISiteChannelModel";
import {Services} from "../../Services";
import {ChannelService} from "../../channel/ChannelService";
import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {Network} from "../../../network/Network";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {AvatarService} from "../../avatar/AvatarService";
import {ChannelType} from "../../../models/channel/ChannelType";
import {MailService} from "../../mail/MailService";
import {IAvatarModel} from "../../../models/avatar/IAvatarModel";
import {AvatarType} from "../../../models/avatar/AvatarType";
import {EntityService} from "../../entity/EntityService";
import {PreferencesService} from "../../preferences/PreferencesService";
import {ComType} from "../../../models/enums/ComType";
import {IAccountModel} from "../../../models/account/IAccountModel";
import {AccountService} from "../../account/AccountService";
import {ProductType} from "../../../models/product/ProductType";
import {WebSocketPanelEventName} from "../../../network/socket/names/WebSocketPanelEventName";
import {ISiteChannelsUpdateOutgoing} from "../../../models/site/channel/ISiteChannelsUpdateOutgoing";
import {ISiteChannelRegisterOutgoingModel} from "../../../models/site/channel/ISiteChannelRegisterOutgoingModel";
import {ErrorCode} from "../../../network/status/error/ErrorCode";
import {Resources} from "../../../resources/Resources";

export class SiteChannelService {
    public static siteChannels: ISiteChannelModel[] = observable([]);
    public static updates: ((update: ISiteChannelsUpdateOutgoing) => void)[] = [];

    public static dispose(): void {
        this.siteChannels = observable([]);
        this.updates = [];
    }

    public static init(): void {
        Services.beforeInit(this);
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.SITE_CHANNEL_UPDATE, (data) => {
            let update: ISiteChannelsUpdateOutgoing = data;
            this.updates.forEach(value => value(update));
        });
    }

    public static async findUrlConnectPartner(invitationId: string | undefined, isLogin: boolean, channelType: ChannelType, communityId?: string, component?: INetworkComponent): Promise<string> {
        let request = await Network.post(ProductType.PANEL, `/oauth/url/${channelType}/${isLogin}`,
            {
                invitationId: invitationId,
                communityId: communityId,
            }, component);

        if (request.status == HttpStatus.OK) {
            return decodeURIComponent(request.data);
        }

        return undefined;
    }

    public static async findBySiteId(siteId: string, component?: INetworkComponent): Promise<ISiteChannelModel[]> {
        //TODO store and cache
        let request = await Network.get(ProductType.PANEL, `/site/channel/${siteId}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return [];
    }

    public static async findBySiteIdAndPerms(siteId: string, product: ProductType, component?: INetworkComponent): Promise<ISiteChannelModel[]> {
        //TODO store and cache
        let request = await Network.get(ProductType.PANEL, `/site/channel/perms/${siteId}/${product}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return [];
    }

    public static async findByType(channelType: ChannelType, component?: INetworkComponent): Promise<ISiteChannelModel[]> {
        //TODO store and cache
        let request = await Network.get(ProductType.PANEL, `/site/channel/type/${channelType}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return [];
    }

    public static async findReliedAccounts(siteChannelId: string, component?: INetworkComponent): Promise<IAccountModel[]> {
        let request = await Network.get(ProductType.PANEL, `/site/channel/relied/accounts/${siteChannelId}`, component);
        if (request.status == HttpStatus.OK) {
            return AccountService.storeAll(request.data);
        }

        return [];
    }

    public static async pickedByPerms(component?: INetworkComponent, channelType?: ChannelType): Promise<ISiteChannelModel> {
        let siteChannels = await this.findBySiteIdAndPerms(EntityService.activeSite.id, ProductType.INBOX, component);
        siteChannels = siteChannels.filter(value => !channelType || value.channel.type == channelType);
        let siteChannel = siteChannels.find(value => value.id == PreferencesService.siteChannelPickerSelected);

        return siteChannel ? siteChannel : siteChannels[0];
    }

    public static async delete(id: string, component?: INetworkComponent): Promise<boolean> {
        let siteChannel = this.siteChannels.filter(value => value.id == id)[0];
        let request = await Network.post(ProductType.PANEL, "/site/channel/delete",
            {
                id: id,
            }, component);

        if (request.status == HttpStatus.OK) {
            this.unStore(siteChannel);
        }

        return request.status == HttpStatus.OK;
    }

    public static async update(id: string, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.put(ProductType.PANEL, "/site/channel/update",
            {
                id: id,
            }, component);

        if (request.status == HttpStatus.OK) {
            this.store(request.data);
        }

        return request.status == HttpStatus.OK;
    }

    public static async register(registerOutgoingModel: ISiteChannelRegisterOutgoingModel, component?: INetworkComponent): Promise<string> {
        Services.handleErrors(component, [
            {errorCode: ErrorCode.PARTNER_CONFING_INVALID, message: Resources.t("words.partnerConnectionFailed")},
            {errorCode: ErrorCode.PARTNER_CONFING_PERMISSIONS, message: registerOutgoingModel.channelType == ChannelType.SHOPIFY
                    ? Resources.t("words.shopifyPermissionsMessage")
                    : Resources.t("words.missingPermissions")},
        ]);
        let request = await Network.postJson(ProductType.PANEL, `/site/channel/register`, registerOutgoingModel, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    /**
     * avatar
     */
    public static avatar(siteChannel: ISiteChannelModel): IAvatarModel {
        if (siteChannel.avatar) {
            return siteChannel.avatar;
        }

        if (siteChannel.mail) {
            return MailService.avatar(siteChannel.mail);
        }

        return {
            type: AvatarType.SITE_CHANNEL,
            color: {
                color: siteChannel.color
            }
        }
    }

    /**
     * logic
     */
    public static comType(siteChannel: ISiteChannelModel): ComType {
        switch (siteChannel.channel.type) {
            case ChannelType.MAIL:
                return ComType.EMAIL;
            default:
                return ComType.DIRECT_MESSAGE;
        }
    }

    /**
     * ws
     */

    public static onUpdates(func: (updates: ISiteChannelsUpdateOutgoing) => void, component: INetworkComponent) {
        this.updates.push(func);
        component.onRemove(() => this.updates.splice(this.updates.indexOf(func), 1));
    }

    /**
     * store
     */

    public static storeAll(siteChannels: ISiteChannelModel[]): ISiteChannelModel[] {
        for (let key in siteChannels)
            siteChannels[key] = this.store(siteChannels[key]);

        return Services.storeAll(siteChannels);
    }

    public static store(siteChannel: ISiteChannelModel): ISiteChannelModel {
        if (siteChannel.avatar != undefined) {
            siteChannel.avatar = AvatarService.store(siteChannel.avatar);
        }

        if (siteChannel.mail != undefined) {
            siteChannel.mail = MailService.store(siteChannel.mail);
        }

        siteChannel.channel = ChannelService.findById(siteChannel.channelId);
        return siteChannel;
    }

    public static unStore(siteChannel: ISiteChannelModel): void {
        Services.unStore("id", this.siteChannels, siteChannel);
    }
}