import {observable} from "@nx-js/observer-util";
import {Services} from "../../Services";
import {ICommunityArticleModel} from "../../../models/community/article/ICommunityArticleModel";
import {ICommunityArticlesModel} from "../../../models/community/article/ICommunityArticlesModel";
import {AccountService} from "../../account/AccountService";
import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {Network} from "../../../network/Network";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {ICommunityArticleViewModel} from "../../../models/community/article/ICommunityArticleViewModel";
import {CommunityCategoryService} from "../categories/CommunityCategoryService";
import {ProductType} from "../../../models/product/ProductType";
import {ICommunityModel} from "../../../models/community/ICommunityModel";
import {ICommunityArticleUpdateModel} from "../../../models/community/article/ICommunityArticleUpdateModel";
import {ErrorCode} from "../../../network/status/error/ErrorCode";
import {Resources} from "../../../resources/Resources";
import {SedestralSsr} from "../../../sedestral-interface-modules/sedestral-interface-component/ssr/SedestralSsr";
import {config} from "../../../config";
import {ProductName} from "../../../models/product/ProductName";
import {WebSocketCommunityEventName} from "../../../network/socket/names/WebSocketCommunityEventName";
import {
    ICommunityArticleGenerateOutgoing
} from "../../../models/community/article/ai/generate/ICommunityArticleGenerateOutgoing";
import {
    ICommunityArticleSectionsOutgoing
} from "../../../models/community/article/ai/sections/ICommunityArticleSectionsOutgoing";
import {
    ICommunityArticleGenerateStart
} from "../../../models/community/article/ai/generate/ICommunityArticleGenerateStart";
import {ICommunityArticleGenerate} from "../../../models/community/article/ai/generate/ICommunityArticleGenerate";
import {
    ICommunityArticleProposalIncomingModel
} from "../../../models/community/article/ai/proposals/ICommunityArticleProposalIncomingModel";
import {
    ICommunityArticleSectionIncomingModel
} from "../../../models/community/article/ai/sections/ICommunityArticleSectionIncomingModel";
import {
    ICommunityArticleSectionsUpdate
} from "../../../models/community/article/ai/sections/ICommunityArticleSectionsUpdate";
import {CommunityFilesService} from "../files/CommunityFilesService";
import {arrayRemove} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/ArrayRemove";
import {
    ICollaborationEditorCommunitySavingArticleMetadataOutgoingModel
} from "../../../models/collaboration/editor/saving/community/outgoing/ICollaborationEditorCommunitySavingArticleMetadataOutgoingModel";
import {
    ICommunityArticleStepCreateOutgoing
} from "../../../models/community/article/ai/create/ICommunityArticleStepCreateOutgoing";
import {
    ICommunityArticleStepKeywordOutgoing
} from "../../../models/community/article/ai/keyword/ICommunityArticleStepKeywordOutgoing";
import {
    ICommunityArticleStepRulesOutgoing
} from "../../../models/community/article/ai/rules/ICommunityArticleStepRulesOutgoing";
import {
    randomInteger
} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/RandomInteger";
import {
    ICommunityArticlesLastUserContextsModel
} from "../../../models/community/article/ICommunityArticlesLastUserContextsModel";
import {IPaginationOutgoing} from "../../../models/pagination/IPaginationOutgoing";
import {
    ICommunityArticlesMonitoringIncoming
} from "../../../models/community/article/monitoring/ICommunityArticlesMonitoringIncoming";
import {CommunityArticleAIState} from "../../../models/community/article/ai/CommunityArticleAIState";

export class CommunityArticleService {

    public static NB_MAX_SECTIONS_BY_ITEM: number = 7;

    public static articles: ICommunityArticleModel[] = observable([]);
    public static createEvents: ((article: ICommunityArticleModel) => void)[] = [];
    public static deleteEvents: ((article: ICommunityArticleModel) => void)[] = [];
    public static updateEvents: ((article: ICommunityArticleModel) => void)[] = [];

    public static proposalEvents: ((proposal: ICommunityArticleProposalIncomingModel) => void)[] = [];
    public static sectionEvents: ((section: ICommunityArticleSectionIncomingModel) => void)[] = [];
    public static generateEvents: ((generate: ICommunityArticleGenerate) => void)[] = [];

    public static generateStartEvents: ((generateStart: ICommunityArticleGenerateStart) => void)[] = [];
    public static generateStarts: ICommunityArticleGenerateStart[] = [];

    public static joinedArticleId: string;

    private static ssrArticleViews: ICommunityArticleViewModel = observable();
    private static ssrArticlesByCategory: ICommunityArticlesModel = observable();

    public static dispose(): void {
        this.createEvents = [];
        this.deleteEvents = [];
        this.updateEvents = [];
        this.proposalEvents = [];
        this.sectionEvents = [];
        this.generateEvents = [];
        this.generateStartEvents = [];
    }

    public static init(): void {
        if (config.product != ProductName.toString(ProductType.LIVECHAT)) {
            window["CommunityArticleService"] = this;
        }

        Network.on(ProductType.COMMUNITY, WebSocketCommunityEventName.COMMUNITY_ARTICLE_CREATE,
            (data) => {
                let article = this.store(data);
                this.createEvents.forEach(value => value(article));
            });

        Network.on(ProductType.COMMUNITY, WebSocketCommunityEventName.COMMUNITY_ARTICLE_UPDATE,
            (data) => {
                let article = this.store(data);
                this.updateEvents.forEach(value => value(article));
            });

        Network.on(ProductType.COMMUNITY, WebSocketCommunityEventName.COMMUNITY_ARTICLE_DELETE,
            (data) => {
                let article = this.store(data);
                this.deleteEvents.forEach(value => value(article));
            });

        Network.on(ProductType.COMMUNITY, WebSocketCommunityEventName.COMMUNITY_ARTICLE_PROPOSAL_CREATE,
            (proposal: ICommunityArticleProposalIncomingModel) => {
                this.storeProposal(proposal);
                this.proposalEvents.forEach(value => value(proposal));
            });

        Network.on(ProductType.COMMUNITY, WebSocketCommunityEventName.COMMUNITY_ARTICLE_SECTION_CREATE,
            (section: ICommunityArticleSectionIncomingModel) => {
                this.storeSection(section);
                this.sectionEvents.forEach(value => value(section));
            });

        Network.on(ProductType.COMMUNITY, WebSocketCommunityEventName.COMMUNITY_ARTICLE_GENERATE,
            (data: ICommunityArticleGenerate) => {
                this.generateEvents.forEach(value => value(data));
            });

        Network.on(ProductType.COMMUNITY, WebSocketCommunityEventName.COMMUNITY_ARTICLE_GENERATE_START,
            (data: ICommunityArticleGenerateStart) => {
                this.generateStarts.push(data);
                this.generateStartEvents.forEach(value => value(data));
            });
    }

    /**
     * websocket
     */

    static onCreateEvent(component: INetworkComponent, func: (article: ICommunityArticleModel) => void) {
        this.createEvents.push(func);
        component.onRemove(() => arrayRemove(this.createEvents, func));
    }

    static onDeleteEvent(component: INetworkComponent, func: (article: ICommunityArticleModel) => void) {
        this.deleteEvents.push(func);
        component.onRemove(() => arrayRemove(this.deleteEvents, func));
    }

    static onUpdateEvent(component: INetworkComponent, func: (article: ICommunityArticleModel) => void) {
        this.updateEvents.push(func);
        component.onRemove(() => arrayRemove(this.updateEvents, func));
    }

    static onProposalEvent(component: INetworkComponent, func: (proposal: ICommunityArticleProposalIncomingModel) => void) {
        this.proposalEvents.push(func);
        component.onRemove(() => arrayRemove(this.proposalEvents, func));
    }

    static onSectionEvent(component: INetworkComponent, func: (section: ICommunityArticleSectionIncomingModel) => void) {
        this.sectionEvents.push(func);
        component.onRemove(() => arrayRemove(this.sectionEvents, func));
    }

    static onGenerateEvent(component: INetworkComponent, func: (generate: ICommunityArticleGenerate) => void, id: string) {
        this.generateEvents.push(func);
        component.onRemove(() => arrayRemove(this.generateEvents, func));
    }

    static onGenerateStartEvent(component: INetworkComponent, articleId: string, func: (generateStart: ICommunityArticleGenerateStart) => void) {
        let start = this.generateStarts.find(value => value.articleId == articleId);
        if (start) {
            func(start);
            arrayRemove(this.generateStarts, start);
        }

        this.generateStartEvents.push(func);
        component.onRemove(() => arrayRemove(this.generateStartEvents, func));
    }

    /**
     * http
     */
    public static async aiStepCreate(create: ICommunityArticleStepCreateOutgoing, component?: INetworkComponent): Promise<ICommunityArticleModel> {
        if (!create.title) {
            create.title = `${Resources.t("words.untitled")} ${randomInteger(1000, 99999)}`;
        }

        let request = await Network.postJson(ProductType.COMMUNITY, "/community/article/step/ai/create", create, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }
        return undefined;
    }

    public static async aiStepKeyword(keyword: ICommunityArticleStepKeywordOutgoing, component?: INetworkComponent): Promise<ICommunityArticleModel> {
        Services.handleErrors(component, [
            {errorCode: ErrorCode.SIZE_MAX, message: Resources.t("words.maxProposalsPerItem")}
        ]);

        let request = await Network.postJson(ProductType.COMMUNITY, "/community/article/step/ai/keyword", keyword, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }
        return undefined;
    }

    public static async aiStepRules(rules: ICommunityArticleStepRulesOutgoing, component?: INetworkComponent): Promise<ICommunityArticleModel> {
        Services.handleErrors(component, [
            {errorCode: ErrorCode.GPT_RETRY_LIMIT, message: Resources.t("words.maxRetryLimit")},
            {errorCode: ErrorCode.SIZE_MAX, message: Resources.t("words.maxRetryLimit")}
        ]);

        let request = await Network.postJson(ProductType.COMMUNITY, "/community/article/step/ai/rules", rules, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }
        return undefined;
    }

    public static async aiProposalsRetry(p: {
        articleId: string,
        index: number
    }, component?: INetworkComponent): Promise<ICommunityArticleModel> {

        Services.handleErrors(component, [
            {errorCode: ErrorCode.GPT_RETRY_LIMIT, message: Resources.t("words.maxRetryLimit")}
        ]);

        let request = await Network.postJson(ProductType.COMMUNITY, `/community/article/ai/proposals/retry`, p, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async aiSections(p: ICommunityArticleSectionsOutgoing, component?: INetworkComponent): Promise<ICommunityArticleModel> {
        Services.handleErrors(component, [
            {errorCode: ErrorCode.GPT_RETRY_LIMIT, message: Resources.t("words.maxRetryLimit")},
            {errorCode: ErrorCode.SIZE_MAX, message: Resources.t("words.maxRetryLimit")}
        ]);

        let request = await Network.postJson(ProductType.COMMUNITY, `/community/article/ai/sections`, p, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async aiSectionsRetry(p: {
        articleId: string,
        index: number
    }, component?: INetworkComponent): Promise<ICommunityArticleModel> {

        Services.handleErrors(component, [
            {errorCode: ErrorCode.GPT_RETRY_LIMIT, message: Resources.t("words.maxRetryLimit")}
        ]);

        let request = await Network.postJson(ProductType.COMMUNITY, `/community/article/ai/sections/retry`, p, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async aiSectionsUpdate(p: ICommunityArticleSectionsUpdate, component?: INetworkComponent): Promise<ICommunityArticleModel> {
        let request = await Network.postJson(ProductType.COMMUNITY, `/community/article/ai/sections/update`, p, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async aiGenerate(p: ICommunityArticleGenerateOutgoing, component?: INetworkComponent): Promise<ICommunityArticleModel> {
        Services.handleErrors(component, [
            {errorCode: ErrorCode.GPT_RETRY_LIMIT, message: Resources.t("words.maxRetryLimit")}
        ]);

        let request = await Network.postJson(ProductType.COMMUNITY, `/community/article/ai/generate`, p, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async view(communityId: string, route: string, component?: INetworkComponent): Promise<ICommunityArticleViewModel> {
        if (SedestralSsr.hasSsr() && this.ssrArticleViews) {
            return Promise.resolve(this.ssrArticleViews);
        }

        let request = await Network.get(ProductType.COMMUNITY, `/community/article/view/${communityId}/${Resources.language}/${route}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeView(request.data);
        }

        return undefined;
    }

    public static async lastUserContexts(communityId: string, component?: INetworkComponent): Promise<ICommunityArticlesLastUserContextsModel[]> {
        let request = await Network.get(ProductType.COMMUNITY, `/community/article/last-user-contexts/${communityId}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    public static async findById(id: string, component?: INetworkComponent): Promise<ICommunityArticleModel> {
        let request = await Network.get(ProductType.COMMUNITY, `/community/article/${id}`, component);
        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async findAll(communityId: string, start: number, limit: number, categoryRoute?: string, component?: INetworkComponent): Promise<ICommunityArticlesModel | undefined> {
        if (SedestralSsr.hasSsr() && this.ssrArticlesByCategory) {
            return Promise.resolve(this.ssrArticlesByCategory);
        }

        const queryParams = new URLSearchParams({
            ...(categoryRoute ? {categoryRoute} : {}),
            language: Resources.language,
            communityId: communityId,
            start: start.toString(),
            limit: limit.toString()
        }).toString();

        let request = await Network.get(ProductType.COMMUNITY, `/community/article?${queryParams}`, component);

        if (request.status == HttpStatus.OK) {
            return this.storeArticles(request.data);
        }

        return undefined;
    }

    public static async generatedCount(communityId: string, component?: INetworkComponent): Promise<number> {
        Services.handleErrors(component, [
            {status: HttpStatus.NOT_FOUND, message: "none"}
        ]);
        let request = await Network.get(ProductType.COMMUNITY, `/community/article/generated/count/${communityId}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async update(update: ICommunityArticleUpdateModel, component?: INetworkComponent): Promise<boolean> {
        Services.handleErrors(component, [
            {errorCode: ErrorCode.ROUTE_EXIST, message: Resources.t("words.routeExist")}
        ]);

        let request = await Network.putJson(ProductType.COMMUNITY, `/community/article`, update, component);
        if (request.status == HttpStatus.OK) {
            this.store(request.data);
            return true;
        }

        return false;
    }

    public static async delete(articleId: string, component?: INetworkComponent): Promise<ICommunityModel> {
        let request = await Network.delete(ProductType.COMMUNITY, `/community/article/${articleId}`, component);
        if (request.status == HttpStatus.OK) {
            this.unStore(request.data);
        }

        return undefined;
    }

    public static async search(communityId: string, language: string, search: string, component?: INetworkComponent): Promise<ICommunityArticlesModel> {
        let request = await Network.get(ProductType.COMMUNITY, `/community/article/list/${communityId}/${language}/${search}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeArticles(request.data);
        }

        return undefined;
    }

    public static async alternate(id: string, component?: INetworkComponent): Promise<ICommunityArticleModel[]> {
        let request = await Network.get(ProductType.COMMUNITY, `/community/article/alternate/${id}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return undefined;
    }

    public static async getArticlesMonitoring(communityId: string, pagination: IPaginationOutgoing, component?: INetworkComponent): Promise<ICommunityArticlesMonitoringIncoming> {
        let request = await Network.postJson(ProductType.COMMUNITY, `/community/articles/monitoring/${communityId}`, pagination, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    /**
     * websocket
     */

    public static async join(id: string) {
        if (id != null) {
            await Network.join(ProductType.COMMUNITY, id, WebSocketCommunityEventName.COMMUNITY_ARTICLE_JOIN);
            this.joinedArticleId = id;
        }
    }


    public static leave(id: string) {
        Network.emit(ProductType.COMMUNITY, WebSocketCommunityEventName.COMMUNITY_ARTICLE_LEAVE, id);
        this.joinedArticleId = undefined;
    }

    /**
     * store
     */


    public static storeView(view: ICommunityArticleViewModel): ICommunityArticleViewModel {
        view.article = this.store(view.article);
        view.category = CommunityCategoryService.store(view.category);
        return view;
    }

    public static storeArticles(articles: ICommunityArticlesModel): ICommunityArticlesModel {
        articles.articles = CommunityArticleService.storeAll(articles.articles);

        return articles;
    }

    public static storeAll(articles: ICommunityArticleModel[]): ICommunityArticleModel[] {
        for (let key in articles) {
            articles[key] = this.store(articles[key]);
        }
        return Services.storeAll(articles);
    }

    public static storeProposal(proposal: ICommunityArticleProposalIncomingModel) {
        let article = this.articles.find(value => value.id == proposal.articleId);
        if (article && article.proposals) {
            article.proposals.items[proposal.index].proposals.push(proposal.proposal);
        }
    }

    public static storeSection(section: ICommunityArticleSectionIncomingModel) {
        let article = this.articles.find(value => value.id == section.articleId);
        if (article && article.sections) {
            article.sections.items[section.indexSection].sections.push(section.section);
        }
    }

    public static store(article: ICommunityArticleModel): ICommunityArticleModel {
        let storedArticle = Services.get("id", this.articles, article.id);
        if (storedArticle != undefined) {

            if (!article.deltas && storedArticle.deltas) {
                article.deltas = storedArticle.deltas;
            }

            if (article.writersAccounts.length == 0 && storedArticle.writersAccounts.length !== 0) {
                article.writersAccounts = storedArticle.writersAccounts;
            }

            if (article.writersRedactors.length == 0 && storedArticle.writersRedactors.length !== 0) {
                article.writersRedactors = storedArticle.writersRedactors;
            }
        }

        if (article.imageFile) {
            article.imageFile = CommunityFilesService.store(article.imageFile);
        }

        AccountService.storeAll(article.writersAccounts);
        return Services.store("id", this.articles, article);
    }

    public static unStore(article: ICommunityArticleModel): void {
        Services.unStore("id", this.articles, article);
    }

    public static storeMetadata(metadata: ICollaborationEditorCommunitySavingArticleMetadataOutgoingModel) {
        if (metadata.imageFile) {
            metadata.imageFile = CommunityFilesService.store(metadata.imageFile);
        }

        AccountService.storeAll(metadata.writersAccounts);
    }

    public static getMainKeyword(article: ICommunityArticleModel): string {
        const doneSection = article?.sections?.items.find(
            section => section.state === CommunityArticleAIState.DONE);

        return doneSection
            ? article?.proposals?.items[doneSection.index]?.keywords?.[0] ?? ""
            : "";
    }


    /**
     * SSR
     */

    public static setContextSsr(context: string) {
        let object = JSON.parse(context);

        if (object.articles) {
            let articles = this.storeArticles(object);
            this.ssrArticlesByCategory = observable(articles);
        } else {
            let article = this.storeView(object);
            this.ssrArticleViews = observable(article);
        }
    }
}