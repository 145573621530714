export enum ComState {
    NONE = 0,
    SPAM = 1,
    TRASH = 2,
    SPAM_ASSASSIN = 3,
    DRAFT = 4,
    PUBLISH_DRAFTS = 7,
    SCHEDULED = 5,
    DELETED = 6,
    TRANSFERRED = 8,
    ERROR = 10,
    ERROR_RED = 12,
    RANK = 11
}