import {Network} from "../../../network/Network";
import {ProductType} from "../../../models/product/ProductType";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {
    Component
} from "../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {
    ICommunitySEOClusterKeywordsIncoming
} from "../../../models/community/seo/cluster/ICommunitySEOClusterKeywordsIncoming";
import {WebSocketCommunityEventName} from "../../../network/socket/names/WebSocketCommunityEventName";
import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {arrayRemove} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/ArrayRemove";
import {
    ICommunitySEOClusterKeywordsModel
} from "../../../models/community/seo/cluster/ICommunitySEOClusterKeywordsModel";
import {
    ICommunitySEOClusterKeywordsPreviewModel
} from "../../../models/community/seo/cluster/ICommunitySEOClusterKeywordsPreviewModel";
import {Services} from "../../Services";
import {ErrorCode} from "../../../network/status/error/ErrorCode";
import {Resources} from "../../../resources/Resources";

export class CommunityClustersService {

    public static events: ((clusters: ICommunitySEOClusterKeywordsModel[]) => void)[] = [];

    public static dispose(): void {
        this.events = [];
    }

    public static init(): void {
        Network.on(ProductType.COMMUNITY, WebSocketCommunityEventName.COMMUNITY_SEO_CLUSTER_KEYWORDS_CREATE, (data: ICommunitySEOClusterKeywordsModel[]) => {
            this.events.forEach(value => value(data));
        });
    }

    public static async create(communityId: string, query: string, component: Component): Promise<boolean> {
        let request = await Network.post(ProductType.COMMUNITY, `/community/clusters/keywords/create/${communityId}/${query}`, {}, component);
        return request.status == HttpStatus.OK;
    }

    public static async update(communityId: string, clusterId: string, keywords: ICommunitySEOClusterKeywordsIncoming[], component: Component): Promise<ICommunitySEOClusterKeywordsModel> {
        Services.handleErrors(component, [
            {
                errorCode: ErrorCode.SIZE_MIN,
                message: Resources.t("words.minKeywords")
            },
            {
                errorCode: ErrorCode.SIZE_MAX,
                message: Resources.t("words.maxKeywords")
            },
            {
                errorCode: ErrorCode.MAX_LIMIT,
                message: Resources.t("words.maxClusterModification")
            }
        ]);

        let request = await Network.putJson(ProductType.COMMUNITY, `/community/clusters/keywords/update/${communityId}/${clusterId}`, keywords, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    public static async getPreviewsByCommunityId(communityId: string, component: Component): Promise<ICommunitySEOClusterKeywordsPreviewModel[]> {
        let request = await Network.get(ProductType.COMMUNITY, `/community/clusters/keywords/${communityId}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    public static async getClusters(communityId: string, clusterId: string, component: Component): Promise<ICommunitySEOClusterKeywordsModel[]> {
        let request = await Network.get(ProductType.COMMUNITY, `/community/clusters/keywords/${communityId}/${clusterId}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    public static async getCompetitors(communityId: string, clusterId: string, component: Component): Promise<ICommunitySEOClusterKeywordsModel> {
        let request = await Network.get(ProductType.COMMUNITY, `/community/clusters/keywords/competitors/${communityId}/${clusterId}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    public static async delete(communityId: string, id: string, component: Component): Promise<ICommunitySEOClusterKeywordsModel> {
        let request = await Network.delete(ProductType.COMMUNITY, `/community/clusters/${communityId}/${id}`, component);
        if (request.status == HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    static onClusterCreate(component: INetworkComponent, func: (cluster: ICommunitySEOClusterKeywordsModel[]) => void) {
        this.events.push(func);
        component.onRemove(() => arrayRemove(this.events, func));
    }
}